import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Rooms } from "./Galary-information";
import { Houses } from "./Galary-information";
import { Surroundings } from "./Galary-information";
import { WithTransLate } from "../../translating";
function CustomTabPanel(props) {
  const { children, value, index, Rooms } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {Rooms.map((room, index) => (
              <Card
                key={index}
                sx={{
                  maxWidth: "45%",
                  mt: 1,
                  mb: 1,
                  ml: 1,
                  borderRadius: "0px",
                }}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={room.image}
                  title={room.title}
                />
                <CardContent
                  sx={{ width: "180px", height: "10px", padding: 0, m: 1 }}
                >
                  <Typography
                    gutterBottom
                    variant="p"
                    fontSize="14px"
                    fontWeight={"medium"}
                    component="div"
                  >
                    <WithTransLate text={room.title} />
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    fontSize="10px"
                    component="div"
                  >
                    <WithTransLate text={room.From} />
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ height: "10px", pt: 3, pb: 3, mt: 6, width: "100%" }}
                >
                  <Button
                    sx={{ width: "80px", textDecoration: "underline", p: 0 }}
                    href={room.Link}
                    target="_blank"
                  >
                 
                  {
                    value===2 ? <WithTransLate text="SEE MORE" />:<WithTransLate text="Book Now" />
                  }
                    
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  Rooms: PropTypes.array.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Gallery = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "800px",
        padding: "50px 0px",
        display: { xs: "block", sm: "none" },
      }}
    >
      <Typography
        gutterBottom
        variant="h6"
        marginLeft="20px"
        fontFamily={"Oblik"}
      >
        <WithTransLate sx={{}} text="GALLERY" />
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab sx={{ width: "33%" }} label="Rooms" {...a11yProps(0)} />
          <Tab sx={{ width: "33%" }} label="Houses" {...a11yProps(1)} />
          <Tab sx={{ width: "33%" }} label="Surroundings" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} Rooms={Rooms} index={0}>
        {/*Rooms*/}
      </CustomTabPanel>
      <CustomTabPanel value={value} Rooms={Houses} index={1}>
        {/*Houses*/}
      </CustomTabPanel>
      <CustomTabPanel value={value} Rooms={Surroundings} index={2}>
        {/*   Surroundings*/}
      </CustomTabPanel>
    </Box>
  );
};

export default Gallery;
