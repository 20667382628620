import { Box, makeStyles } from "@material-ui/core";
import selfie from "../../images/selfie.jpg";
import { Element } from "react-scroll";
import React from "react";
import { Link } from "react-router-dom";
import { WithTransLate } from "../../translating/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "130px 0px 75px",
    [theme.breakpoints.down("md")]: {
      width: "fit-content",
      margin: "100px auto",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  titleStyle: {
    fontFamily: "Oblik",
    transform: "rotate(-90deg)",
    height: "fit-content",
    width: "fit-content",
    margin: "0 0 0 -58px",
    fontSize: "24px",
    fontWeight: "700",
    letterSpacing: "0.1em",
    marginTop: "-13px",
  },
  outLinedImage: {
    width: "auto",
    height: "420px",
    padding: "2%",
    border: "1px solid",
    marginLeft: "-2%",
  },
  selfieStyle: {
    width: "495px",
    height: "462px",
    marginTop: "-90px",
    objectFit: "cover",
    [theme.breakpoints.up("lg")]: {
      width: "35vw",
    },
  },
  description: {
    fontSize: "24px",
    fontWeight: 300,
    fontFamily: "Josefin Sans",
    lineHeight: "30px",
    letterSpacing: "0.10em",
    textAlign: "center",
  },
  descriptionWrapper: {
    alignItems: "center",
    gap: "30px",
    width: "70vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      width: "553.6px",
      gap: "auto",
    },
  },
  content: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "40px",
    },
  },
  KnowMore: {
    width: "200px",
    height: "49px",
    background: "transparent",
    fontFamily: "Josefin Sans",
    fontSize: "18px",
    lineHeight: "24px",
    border: "1px solid #14202B",
    color: "#14202B !important",
    paddingTop: "7px",
    cursor: "pointer",
    "& p": {
      marginBottom: "0px",
    },
    "&:hover": {
      background:
        "linear-gradient( 180deg,#04376f 99.99%,rgba(255, 255, 255, 0) 100%), #ffffff",
      color: "#ffffff !important",
      borderColor: "#04376f !important",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "30px",
    },
  },
}));

export default function AboutUs() {
  const {
    root,
    content,
    titleStyle,
    outLinedImage,
    selfieStyle,
    description,
    descriptionWrapper,
    KnowMore,
  } = useStyles();
  return (
    <Element id="aboutus">
      <Box className={root}>
        <h1 className={titleStyle}>
          <WithTransLate text="ABOUT US" />{" "}
        </h1>
        <div className={content}>
          <div className={outLinedImage}>
            <img className={selfieStyle} alt="selfie" src={selfie} />
          </div>
          <div className={descriptionWrapper}>
            <p className={description}>
              <WithTransLate text="Blue House Bed and Breakfast welcomes you to your home away from home in Reykjavik, Iceland. Simply put, we are a small team of globetrotters, passionate about unforgettable travel experiences." />
            </p>
            <Link to="/About-us">
              <button className={KnowMore}>
                <p>
                  <WithTransLate text="DISCOVER MORE" />
                </p>
              </button>
            </Link>
          </div>
        </div>
      </Box>
    </Element>
  );
}
